import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Login/Login.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../../Layouts/Navbar/Navbar";
import { userLogin } from "../../../../data/auth/authAction";
import { toast } from "react-toastify";
import { resetLogin } from "../../../../data/auth/authSlice";

const Login = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(!show);
  }
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, error, isLoggedin } = useSelector((state) => state.auth);
  // const [login, { isLoading, isError }] = useLoginMutation();
  const user = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    dispatch(resetLogin());
    const getSlug = Cookies.get("jellosite-current-slug");
    const redirectUrl = new URLSearchParams(location.search).get("redirect");

    if (isLoggedin && !getSlug) {
      navigate(`/sites?redirect=${redirectUrl}`);
    } else if (isLoggedin && redirectUrl) {
      window.location.href = `${redirectUrl}`;
    } else if (isLoggedin && user && user.sites.length < 1) {
      navigate("/onboarding"); // sends user to CREATE SITE PAGE
    } else if (isLoggedin && user) {
      navigate("/sites"); //send user to his sites page
    }
  }, [user, location.search, dispatch, isLoggedin, navigate]);

  const data = {
    username: emailInput.toLowerCase(),
    password: passwordInput,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      dispatch(userLogin(data));
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <Navbar />
      <div className="login">
        <div className="auth-left-login">
          <h1>Create your website in a few clicks</h1>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} color="#7F56D9" />
              <span>Fully responsive</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} color="#7F56D9" />
              <span>Modular design</span>
            </li>
          </ul>
        </div>
        <div className="auth-right-login">
          {/* <img src={bgc} alt={bgc} className="bgc" /> */}
          <div className="login-card">
            <h2 className="">Sign In to JelloSite</h2>
            <form onSubmit={handleSubmit}>
              {error && (
                <span className="error-msg">
                  <FontAwesomeIcon icon={faWarning} color="red" />
                  {error == "Request failed with status code 400"
                    ? "Invalid Email or password"
                    : "Check Internet Connection and Try again"}
                </span>
              )}
              <div className="input">
                <input
                  required
                  className="pl-2"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  type="email"
                  placeholder="Email Address"
                />
                <div className="input-pass">
                  <input
                    required
                    className="pl-2"
                    value={passwordInput}
                    onChange={(e) => {
                      setPasswordInput(e.target.value);
                    }}
                    type={show ? "text" : "password"}
                    placeholder="Password"
                  />
                  <FontAwesomeIcon
                    icon={show ? faEye : faEyeSlash}
                    onClick={handleShow}
                  />
                </div>
              </div>
              <div className="rem-pass">
                <div className="rem">
                  <input type="checkbox" />
                  <span>Remember me</span>
                </div>
                <span>Forgot Password?</span>
              </div>
              <button type="submit">
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Login"}
              </button>
            </form>
            {/* <button>
              <img src={google} alt={google} />
              <span>Sign In with Google</span>
            </button> */}
            <span>
              Don&apos;t have an account?{" "}
              <Link to="/signup" style={{ color: "#7F56D9" }}>
                Sign Up
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
